import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GlassMagnifier } from 'react-image-magnifiers';

import ProductPriceSpider from 'gatsby-theme-husky/src/components/Products/ProductPriceSpider';
import PlainImage from 'gatsby-theme-husky/src/common/Image/PlainImage';
import Image from 'gatsby-theme-husky/src/common/Image';
import ProductDetailsButton from 'gatsby-theme-husky/src/common/ProductDetailsButton/ProductDetailsButton';

import { ProductDetailsProps } from 'gatsby-theme-husky/src/components/ProductDetails/models';
import { ESCAPE_KEY } from 'gatsby-theme-husky/src/components/ProductDetails/constants';
import ScreenRecognitionContext from 'gatsby-theme-husky/src/utils/context/screenRecognitionContext';
import { extractUrlFromMultiPicker } from 'gatsby-theme-husky/src/utils/umbracoControlsUtils';

import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';

import 'gatsby-theme-husky/src/components/ProductDetails/ProductDetails.scss';
import './ProductDetailsOverride.scss';

const ProductDetails: FC<ProductDetailsProps> = ({
  productTitle,
  productTitleLong,
  productTitleShort,
  localProductImage,
  productImage,
  productFeature1,
  productFeature2,
  productFeature3,
  productFeature4,
  productFeature5,
  productFeature6,
  productFeature7,
  productFeature8,
  productDescriptionLong,
  productDisclaimers,
  productEAN,
  productTitleWithSize,
  closeModalAriaLabel,
  productContent,
  dropdownButton,
  shops,
  productInfo,
  ...itemProductDescriptions
}) => {
  const { variants } = (productContent && productContent.length && productContent[0]) || {};

  const screenRecognitionContext = useContext(ScreenRecognitionContext);
  const [isSupportingWebp, setIsSupportingWebp] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openedSlideNumber, setOpenedSlideNumber] = useState<number | null>(0);

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOpenItem = (i) => {
    openedSlideNumber === i ? setOpenedSlideNumber(null) : setOpenedSlideNumber(i);
  };

  const handleEscFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === ESCAPE_KEY) {
      setIsModalVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscFunction);
    setIsSupportingWebp(
      document.createElement('canvas').toDataURL('image/webp')?.indexOf('data:image/webp') === 0
    );

    return () => {
      document.removeEventListener('keydown', handleEscFunction);
    };
  }, []);

  const featuresArray: string[] = [
    productFeature1,
    productFeature2,
    productFeature3,
    productFeature4,
    productFeature5,
    productFeature6,
    productFeature7,
    productFeature8,
  ];

  const glassMagnifierProps = {
    imageAlt: productTitle || 'Veet product',
    square: true,
    className: 'product-details-page__image--zoom',
    magnifierBorderColor: 'rgb(204, 204, 204)',
    magnifierBorderSize: 1,
    magnifierSize: '150px',
  };

  return (
    <div className="product-details-page" data-testid="products-details">
      <div className="product-details-page__image--wrapper">
        <div
          className="product-details-page__image"
          data-testid="product-details-image"
          role="button"
          tabIndex={0}
          onClick={handleModal}
        >
          {screenRecognitionContext.isMobile ? (
            localProductImage ? (
              <Image localImage={localProductImage} alt={productTitle!} />
            ) : productImage ? (
              <PlainImage lazyLoad src={productImage} alt={productTitle!} />
            ) : null
          ) : localProductImage ? (
            <GlassMagnifier
              {...glassMagnifierProps}
              imageSrc={
                isSupportingWebp
                  ? localProductImage.childImageSharp.fluid.srcWebp
                  : localProductImage.childImageSharp.fluid.src
              }
            />
          ) : productImage ? (
            <GlassMagnifier {...glassMagnifierProps} imageSrc={productImage} />
          ) : null}
        </div>
      </div>
      {isModalVisible ? (
        <div className="product-modal">
          <div
            className="product-modal__overlay"
            onClick={handleModal}
            role="button"
            aria-label={closeModalAriaLabel}
            tabIndex={0}
          />
          <div className="product-modal__body">
            <button
              aria-label={closeModalAriaLabel}
              className="product-modal__close"
              onClick={handleModal}
              type="button"
            />
            {localProductImage ? (
              <Image localImage={localProductImage} alt={productTitle!} />
            ) : null}
            {!localProductImage && productImage ? (
              <PlainImage lazyLoad src={productImage} alt={productTitle!} />
            ) : null}
          </div>
        </div>
      ) : null}
      <div className="product-details-page__content--wrapper">
        <div className="product-details-page__content">
          <h2 className="product-details-page__title">
            {productTitleLong || productTitleShort || productTitle || productTitleWithSize}
          </h2>
          <div className="product-details-page__btn" data-testid="product-card-buttons">
            {dropdownButton ? (
              <ProductDetailsButton
                shops={shops}
                dropdownButton={dropdownButton}
                isModalVisible={isModalVisible}
              />
            ) : (
              <ProductPriceSpider productEAN={productEAN} />
            )}
          </div>
          {variants ? (
            <div className="product-details-page__variants">
              {variants.map(({ variantLink, variantText }) => (
                <Link
                  to={extractUrlFromMultiPicker(variantLink)}
                  key={variantText}
                  className="product-details-page__variants-size"
                  activeClassName="product-details-page__variants-size--active"
                >
                  {variantText}
                </Link>
              ))}
            </div>
          ) : null}
          <div className="product-details-page__description">
            <DangerouslySetInnerHtml html={productDescriptionLong} />
          </div>
          <div className="product-details-page__features">
            <ul className="product-details-page__features-items">
              {featuresArray.map((item) =>
                item.length > 0 ? (
                  <li className="product-details-page__features-item" key={item}>
                    <DangerouslySetInnerHtml html={item} />
                  </li>
                ) : null
              )}
            </ul>
          </div>
          {productDisclaimers ? (
            <DangerouslySetInnerHtml
              html={productDisclaimers}
              className="product-details-page__disclaimers"
            />
          ) : null}

          {Object.values(itemProductDescriptions) && productInfo && productInfo?.length > 0 && (
            <div className="product-details-page__feature-accordion">
              {productInfo?.map((elem, i) =>
                itemProductDescriptions[elem?.infoType[0]] ? (
                  <div
                    key={i}
                    onClick={() => handleOpenItem(i)}
                    className={classNames({
                      open: openedSlideNumber === i,
                    })}
                  >
                    <div>{elem.title}</div>
                    <div>
                      <DangerouslySetInnerHtml
                        html={itemProductDescriptions[elem?.infoType[0]]}
                        className="product-details-page__feature-content"
                      />
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
